import type { PartnersBodyType } from '~/composables/partners/type/partnersType';
import type { ServerResponseCommonType } from '~/types/commons';
import type { FetchError } from 'ofetch';

type UsePartnersType = {
	sendPartner:(options:PartnersBodyType)=>Promise<boolean>;
}

export const usePartners = ():UsePartnersType => {
  const { $api } = useNuxtApp();
  async function sendPartner (options:PartnersBodyType): Promise<boolean> {
    const res = await $api<ServerResponseCommonType>('partner/sendApplication', {
      method: 'POST',
      body: { ...options }
    }).catch((err:FetchError) => { throw err; });

    return res && res.message === 'Success';
  }
  return {
    sendPartner
  };
};
